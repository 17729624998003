















































































import { Route } from 'vue-router'
import { Component, Vue } from 'vue-property-decorator'
import { organisationDocumentsModule, stateModule } from '@/store/index'

import DefaultModal    from '@/components/modals/Default.vue'
import DocumentPreview from '@/components/modals/DocumentPreview.vue'

import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

@Component({
  components: {
    DefaultModal,
    DocumentPreview
  },
})
export default class DocumentDetailIndex extends Vue {
  private showModal: boolean = false

  private get document(): OrganisationDocumentResource | null {
    return organisationDocumentsModule.detail
  }


  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    try {
      const ID = parseInt(to.params.document_id, 10)
      await organisationDocumentsModule.getById(ID)
      next()
    } catch (e) {
      next({ name: 'organisation-documents-overview' })
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any): Promise<void> {
    if (organisationDocumentsModule.detail && parseInt(to.params.document_id, 10) === organisationDocumentsModule.detail.id) {
      next()
    } else {
      // Different document so get new one
      stateModule.setLoading(true)
      try {
        const ID = parseInt(to.params.document_id, 10)
        await organisationDocumentsModule.getById(ID)
        next()
      } catch (e) {
        next(from.path)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private goBack(): void {
    this.$router.push({ name: 'organisation-documents-overview' })
  }

  private toggleDocumentInModal(): void {
    this.showModal = !this.showModal
  }
}
