

















import { Vue, Component, Prop }    from 'vue-property-decorator'

import CommentBox  from '@/components/inputs/CommentBox.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { DocumentResource }     from '@/models/documents/DocumentResource'
import { CommentCreateRequest } from '@/requests/comments/CommentCreateRequest'
import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

@Component({
  components: {
    CommentBox,
    SmallLoader
  }
})
export default class DocumentDetailNotesPartial extends Vue {

  @Prop({ type: Object, required: true })
  private data!: DocumentResource | OrganisationDocumentResource | null

  private message: string = ''
  private loading: boolean = false

  private get canComment(): boolean {
    return this.data ? this.data.canPerformAction('can_comment') : false
  }

  private async submit(): Promise<void> {
    if (!this.data) {
      return
    }
    this.loading = true

    const comment = new CommentCreateRequest({
      message: this.message,
      commentable_type: 'document',
      commentable_id: this.data.id
    })

    try {
      await this.data.postComment(comment)
      this.message = ''
    } finally {
      this.loading = false
    }
  }

}
