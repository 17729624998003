import { CommentableType } from '@/models/comments/CommentResource'

export interface ICommentCreateRequest {
  message?: string
  status?: 'unresolved' | 'resolved' | 'declined'
  commentable_type: CommentableType
  commentable_id: number | string
  parent_id?: number,
  project_id?: number
  entity_id?: number
}
export class CommentCreateRequest {
  public message: string = ''
  public status: 'unresolved' | 'resolved' | 'declined'
  public commentable_type: CommentableType
  public commentable_id: number | string

  public project_id?: number
  public parent_id?: number
  public entity_id?: number

  constructor({ message = '', status = 'unresolved', parent_id, commentable_type, commentable_id, entity_id }: ICommentCreateRequest) {
    this.message = message
    this.status = status
    if (parent_id) this.parent_id = parent_id
    if (entity_id) this.entity_id = entity_id
    this.commentable_type = commentable_type
    this.commentable_id = commentable_id
  }
}
