

















































































































import { stateModule } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { OrganisationDocumentMetaField, OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'
import { DocumentVersionRequest } from '@/requests/documents/DocumentVersionRequest'

import TextInput from '@/components/inputs/Text.vue'
import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: {
    TextInput,
    DefaultModal,
  },
})
export default class OrganisationDocumentDetailsPartial extends Vue {

  @Prop({ type: Object, required: true })
  private document!: OrganisationDocumentResource | null

  private async clone(): Promise<void> {
    if (!this.document) {
      return
    }
    try {
      stateModule.setLoading(true)
      this.$emit('submit')
    } catch ({ errors, message }) {
      stateModule.setLoading(false)
    }
  }

  private getFieldValue(field: OrganisationDocumentMetaField): string {
    return Array.isArray(field.value) ? field.value.join(', ') : field.value
  }

  private getStatusString(status: string): string {
    const statusString = status.replace('-', ' ')
    return statusString.charAt(0).toUpperCase() + statusString.slice(1)
  }

}
