





































































import pdfjsLib from 'pdfjs-dist/webpack'

import { toggleModal } from '@/helpers'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import DocumentDetailNotesPartial from '@/components/document/Notes.vue'
import OrganisationDocumentDetailsPartial from '@/components/document/OrganisationDetails.vue'

import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'
import { organisationDocumentsModule, stateModule } from '@/store'

@Component({
  components: {
    DocumentDetailNotesPartial,
    OrganisationDocumentDetailsPartial
  },
})
export default class DocumentPreviewModal extends Vue {
  @Prop({ required: true })
  private document!: OrganisationDocumentResource

  @Prop()
  private open!: boolean

  private page: 'details' | 'notes' = 'details'

  private viewer: any = null

  @Watch('open')
  private onOpenChange(val: boolean): void {
    toggleModal(val)
    if (val) {
      window.addEventListener('keyup', this.checkKey)
      this.$nextTick(() => {
        this.openPdfViewer()
      })
    } else {
      window.removeEventListener('keyup', this.checkKey)
    }
  }

  private async openPdfViewer(): Promise<void> {
    const viewerlib = require('pdfjs-dist/web/pdf_viewer')
    this.viewer = new viewerlib.PDFViewer({
      container: this.$refs.container as HTMLElement,
    })
    if (this.document.pdf_link) {
      stateModule.setLoading(true)

      pdfjsLib
          .getDocument({
            url: this.document.pdf_link,
            withCredentials: true,
          })
          .promise.then((doc: any) => {
        this.viewer.setDocument(doc)
        stateModule.setLoading(false)
      })
    }
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private get detail(): OrganisationDocumentResource | null {
    return organisationDocumentsModule.detail
  }

  private checkKey(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      this.close()
    }
  }

  private close(): void {
    this.$emit('close')
  }

  private download(): void {
    if (!this.detail) {
      return
    }

    window.open(this.detail.pdf_link, '_blank')
  }

  private goTo(page: 'details' | 'notes'): void {
    this.page = page
  }
}
