var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"documentIndex"}},[_c('header',{staticClass:"container-fluid"},[_c('div',{staticClass:"row top-bar"},[_c('div',{staticClass:"col-10 d-flex align-items-start"},[_c('h2',{staticClass:"weight-bold",attrs:{"title":_vm.document.document_name},domProps:{"textContent":_vm._s(_vm.document.document_name)}})]),_c('div',{staticClass:"col-2 d-flex justify-content-end align-items-start"},[_c('button',{staticClass:"cross",on:{"click":_vm.goBack}},[_c('crossIcon')],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 actions"},[_c('button',{staticClass:"button-rounded grey",on:{"click":_vm.toggleDocumentInModal}},[_vm._v("View")])])])]),_c('nav',{staticClass:"container-fluid"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-4"},[_c('router-link',{attrs:{"to":{
            name: 'organisation-documents-single',
            params: { document_id: _vm.document.id },
            query: _vm.$route.query,
          },"exact":""}},[_vm._v(" Details ")])],1),_c('div',{staticClass:"col-4"},[_c('router-link',{attrs:{"to":{
            name: 'organisation-documents-single-history',
            params: { document_id: _vm.document.id },
            query: _vm.$route.query,
          }}},[_vm._v(" History ")])],1),_c('div',{staticClass:"col-4"},[_c('router-link',{attrs:{"to":{
            name: 'organisation-documents-single-notes',
            params: { document_id: _vm.document.id },
            query: _vm.$route.query,
          }}},[_vm._v(" Notes ")])],1)]),_c('div',{staticClass:"line"},[_c('div',{staticClass:"inner-line",class:{ second: _vm.$route.name === 'organisation-documents-single-history', last: _vm.$route.name === 'organisation-documents-single-notes' }})])]),_c('div',{staticClass:"router-wrapper"},[_c('router-view')],1),_c('DocumentPreview',{attrs:{"open":_vm.showModal,"document":_vm.document},on:{"close":function($event){_vm.showModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }